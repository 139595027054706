import type { Country, cartAPI } from '@backmarket/http-api'
import { getShippingStates } from '@backmarket/nuxt-module-address/utils'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

export function cleanAddressState(address: cartAPI.Deliver | cartAPI.Bill) {
  const states = getShippingStates(address.country as Country)

  if (isEmpty(states)) {
    return { ...address, stateOrProvince: '' }
  }

  return { ...address }
}
