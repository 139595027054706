import { createHttpEndpoint } from '../../utils'

import type { PostCheckResponse } from './promotion-codes.types'

/**
 * ReferralCodeView
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/promotion-codes-api/definition#operation/get-bm-client-referrals-referrer}
 */
export const getReferral = createHttpEndpoint({
  method: 'GET',
  operationId: 'getReferFriendInfos',
  path: '/bm/client/referrals/referrer',
})

/**
 * GenerateReferralCodeView
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/promotion-codes-api/definition#operation/post-bm-client-referrals-referrer-generate}
 */
export const postReferralGenerate = createHttpEndpoint({
  method: 'POST',
  operationId: 'generatePromoCode',
  path: '/bm/client/referrals/referrer/generate',
})

/**
 * Check if promotion is valid
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/promotion-codes-api/definition#operation/post-bm-promotion-check}
 */

export const postCheck = createHttpEndpoint<PostCheckResponse>({
  method: 'POST',
  operationId: 'discountCheck',
  path: '/bm/promotion/check',
  transformRequestToSnakeCase: true,
  transformResponseToCamelCase: true,
})
