import { Country, buybackCustomerAPI, cartAPI } from '@backmarket/http-api'
import { postAddress } from '@backmarket/http-api/src/api-specs-after-sale-experience/client/shipping-address'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { defineStore } from 'pinia'

import { FORM_TYPES } from '../types'
import { cleanAddressState } from '../utils/cleanAddressState'
import { saveBouyguesAddress } from '../utils/saveBouyguesAddress'

interface AddressStore {
  shipping: cartAPI.Deliver
  billing: cartAPI.Bill
  collectionPoint: cartAPI.DeliverCollectionPoint | undefined
  shippableCountries: cartAPI.ShippableCountry[]
  billableCountries: cartAPI.BillableCountry[]
}

const addUserInformation = async ({
  address,
  formType,
}: {
  address: cartAPI.Deliver | cartAPI.Bill
  formType: (typeof FORM_TYPES)[keyof typeof FORM_TYPES]
}) => {
  const userInformationBody = {
    birthdate: address.birthdate,
    ...(address.customerIdNumber && {
      taxId: address.customerIdNumber,
    }),
  }

  await $httpFetch(cartAPI.postUpdateUserInformation, {
    body: { ...userInformationBody, formType },
  })
}

export const useAddressStore = defineStore('addressStore', {
  state: (): AddressStore => ({
    shipping: {
      birthdate: '',
      city: '',
      company: '',
      country: '' as Country,
      countryDialInCode: '',
      customerIdNumber: '',
      dateCreation: '',
      dateModification: '',
      email: '',
      firstName: '',
      firstNamePronunciation: '',
      lastName: '',
      lastNamePronunciation: '',
      phone: '',
      postalCode: '',
      stateOrProvince: '',
      street: '',
      street2: '',
      warnings: [],
    },
    billing: {
      birthdate: '',
      city: '',
      company: '',
      country: '' as Country,
      countryDialInCode: '',
      customerIdNumber: '',
      dateCreation: '',
      dateModification: '',
      email: '',
      firstName: '',
      firstNamePronunciation: '',
      lastName: '',
      lastNamePronunciation: '',
      phone: '',
      postalCode: '',
      stateOrProvince: '',
      street: '',
      street2: '',
    },
    collectionPoint: undefined,
    shippableCountries: [],
    billableCountries: [],
  }),
  getters: {
    isShippingAddressComplete: ({ shipping = {} }) => {
      return (
        !isEmpty(shipping) &&
        !isEmpty(`${shipping.firstName} ${shipping.lastName}`.trim()) &&
        !isEmpty(shipping.street?.trim()) &&
        !isEmpty(shipping.postalCode?.trim()) &&
        !isEmpty(shipping.city?.trim()) &&
        !isEmpty(shipping.country) &&
        !isEmpty(shipping.countryDialInCode) &&
        !isEmpty(shipping.phone)
      )
    },
    isBillingAddressComplete: ({ billing = {} }) => {
      return (
        !isEmpty(billing) &&
        !isEmpty(`${billing.firstName} ${billing.lastName}`.trim()) &&
        !isEmpty(billing.street?.trim()) &&
        !isEmpty(billing.postalCode?.trim()) &&
        !isEmpty(billing.city?.trim()) &&
        !isEmpty(billing.country)
      )
    },
    hasCollectionPoint: (state) => !isEmpty(state.collectionPoint),
    isCollectionPointAddressComplete: ({ collectionPoint }) => {
      return (
        !isEmpty(collectionPoint) &&
        !isEmpty(collectionPoint.firstName) &&
        !isEmpty(collectionPoint.lastName) &&
        !isEmpty(collectionPoint.countryDialInCode) &&
        !isEmpty(collectionPoint.phone)
      )
    },
  },
  actions: {
    setAddress({
      shippableCountries,
      billableCountries,
      addressList,
    }: cartAPI.CartResponse) {
      this.shipping = {
        ...addressList.deliver,
        customerIdNumber: addressList.bill?.customerIdNumber ?? '',
      }

      this.billing = addressList.bill
      this.collectionPoint =
        addressList.deliverCollectionPoint as cartAPI.DeliverCollectionPoint
      this.shippableCountries = shippableCountries
      this.billableCountries = billableCountries
    },
    async saveAddress({
      isShipping,
      isBilling,
      formType,
      address,
    }: {
      isShipping: boolean
      isBilling: boolean
      formType: (typeof FORM_TYPES)[keyof typeof FORM_TYPES] | undefined
      address: cartAPI.Deliver | cartAPI.Bill
    }) {
      // 0. Clean states for addresses that doesn't have one
      const cleanedAddress = cleanAddressState(address)

      // 1. Shipping and/or billing address (ensure we have a valid address for swap/insurance)
      const body = {
        isShipping,
        sameAsShipping: isBilling,
        ...cleanedAddress,
      }

      await $httpFetch(postAddress, { body })

      // 2. User information (needed only if insurance)
      const shouldUpdateUserInfo = formType === FORM_TYPES.INSURANCE_ONLY

      if (shouldUpdateUserInfo) {
        await addUserInformation({ address: cleanedAddress, formType })
      }
    },
    async saveBouyguesAddress({
      address,
      isShipping,
      isBilling,
    }: {
      address: cartAPI.Deliver | cartAPI.Bill
      isShipping: boolean
      isBilling: boolean
    }) {
      if (isShipping && isBilling) {
        await saveBouyguesAddress(address, 'BILLING_AND_SHIPPING')
      } else {
        await saveBouyguesAddress(address, isShipping ? 'SHIPPING' : 'BILLING')
      }
    },
    async createBuybackAddress(address: cartAPI.Deliver) {
      const cleanedAddress = cleanAddressState(address)

      await $httpFetch(buybackCustomerAPI.postBuyBackAddress, {
        body: { ...cleanedAddress },
      })
    },
    async saveCollectionPoint({
      formType,
      address,
    }: {
      formType: (typeof FORM_TYPES)[keyof typeof FORM_TYPES] | undefined
      address: cartAPI.Deliver | cartAPI.Bill
    }) {
      await $httpFetch(cartAPI.postUpdateCollectionPointCustomerDetails, {
        body: {
          countryDialInCode: address.countryDialInCode,
          firstName: address.firstName,
          lastName: address.lastName,
          phone: address.phone,
        },
      })

      // 2. User information (needed only if insurance)
      if (formType === FORM_TYPES.INSURANCE_ONLY) {
        await addUserInformation({ address, formType })
      }
    },
  },
})
