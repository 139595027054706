<template>
  <div
    class="bg-surface-default-mid fixed inset-0 z-10 grid items-center md:absolute"
  >
    <RevLoadingScreen
      data-qa="loading-screen"
      :text="i18n(translations.loading)"
    />
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { onMounted } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import { CHECKOUT } from '../../routes-names'
import { useCartStore } from '../../stores/cartStore'

import translations from './PostAuthentication.translations'

const i18n = useI18n()
const logger = useLogger()
const router = useRouter()
const cartStore = useCartStore()

onMounted(async () => {
  logger.debug('[Checkout] Client coming back from authentication')

  try {
    // Reload cart, which updates addresses.
    await cartStore.fetchCart()

    router.push({
      name: cartStore.isShippable
        ? CHECKOUT.ADDRESS_CONFIRMATION
        : CHECKOUT.SHIPPING_ADDRESS,
    })
  } catch {
    // Empty catch until we tackle the following issue: CK-3553
  }
})
</script>
