import type { cartAPI } from '@backmarket/http-api'
import { saveBouyguesAddress as saveBouyguesAddressEndpoint } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan'
import type {
  BouyguesAddressType,
  SaveBouyguesAddressBody,
} from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/bouygues-address'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

type NormalizableAddress = cartAPI.Deliver | cartAPI.Bill

/**
 * Normalize and save the address. If there is no error the user can proceed to the next step, without editing the address.
 */
export const saveBouyguesAddress = async (
  address: NormalizableAddress,
  type: BouyguesAddressType,
) => {
  // TODO [B2CS-273]: Handle API validation errors
  await $httpFetch(saveBouyguesAddressEndpoint, {
    body: {
      partner: 'BOUYGUES_TELECOM',
      addressType: type,
      country: address.country,
      firstName: address.firstName,
      lastName: address.lastName,
      addressLine1: address.street ?? '',
      addressLine2: address.street2,
      postCode: address.postalCode,
      city: address.city,
    } satisfies SaveBouyguesAddressBody,
  })
}

/**
 * Try to normalize the address. If the address is not valid (or any error occurs), it will return false.
 */
export const tryToSaveBouyguesAddress = async (
  address: NormalizableAddress,
  type: BouyguesAddressType,
) => {
  try {
    await saveBouyguesAddress(address, type)

    return true
  } catch {
    // do nothing
  }

  return false
}
